.People-Container {
    display: flex;
    width: 100%;
    height: 90%;
}

.data {
    flex: auto;
    margin: auto;
    width: auto;
}

.newData {
    position: relative;
    flex: auto;
    height: auto;
    width: auto;
    padding: 1em;
    margin: 1em 2em 1em 0;
    background-color: #e5e5e5;
}

.newData-films {
    position: relative;
    flex: auto;
    height: auto;
    width: 60vw;
    padding: 1em;
    margin: 1em 2em 1em 0;
    background-color: #e5e5e5;
}

.newData-films li {
    font-size: large;
}

.newData p {
    font-size: 1em;
    text-align: left;
}

ul li {
    position: relative;
    font-size: 20px;
    padding: 0.5em;
    width: 15em;
    height: auto;
    list-style: none;
    background-color: #e5e5e5;
    margin: 1em 0 0 1em;
}

ul li:hover {
    background-color: #14213d;
}

.data ul {
    position: relative;
    width: 10%;
}


.loading {
    position: absolute;
    left: 50%;
    top:50%;
  }